import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { object, string } from 'yup';

import {
  TextField,
  Box,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';

import { LoginLayout } from '@components/layout/LoginLayout';
import { PasswordInput } from '@components/form/PasswordInput';
import { useAuth } from '@context/auth';

function Login() {
  const schema = object({
    email: string().lowercase().trim().required('E-mail inválido').email('Email inválido'),
    password: string()
      .trim()
      .required('A senha deve ter no mínimo 6 caracteres')
      .min(6, 'A senha deve ter no mínimo 6 caracteres'),
  });

  const [values, setValues] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});

  const [openError, setOpenError] = useState(false);

  const auth = useAuth();

  const changeHandler = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    if (errors[name]) {
      const err = { ...errors };
      delete err[name];

      setErrors(err);
    }

    setValues((old) => ({ ...old, [name]: value }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const validate = await schema.validate(values, { abortEarly: false });

      const result = await auth.login(validate);

      if (result.error) {
        // openMessage({ message: 'Falha no envio: e-mail ou senha inválido.' });
        setOpenError(true);
        return;
      }

      navigate('/');
    } catch (err) {
      const errs = {};
      err.inner.forEach((item) => (errs[item.path] = item.message));

      setErrors(errs);
    }

    return false;
  };

  const forgotHandler = (e) => {
    e.preventDefault();
    navigate('/forgot-password');
  };

  const registerHandler = () => {
    navigate('/register');
  };

  const centralHandler = (e) => {
    e.preventDefault();
    navigate('/central_de_ajuda');
  };

  const closeErrorHandler = () => {
    setOpenError(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: '90px' }}>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Olá, bem vindo!
          </Typography>
          <Typography variante="body1">Faça seu login.</Typography>
        </Box>
        <Box
          sx={{
            py: '5rem',
            borderTop: '1px solid var(--gray-3)',
            borderBottom: '1px solid var(--gray-3)',
            mt: '1.5rem',
          }}
        >
          <Grid component="form" container spacing={3} onSubmit={submitHandler}>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="e-mail"
                fullWidth
                helperText={errors.email ?? ' '}
                error={Boolean(errors.email)}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                name="password"
                label="Senha"
                fullWidth
                helperText={errors.password ?? ' '}
                error={Boolean(errors.password)}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button type="submit" variant="contained">
                entrar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', pb: '3rem' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              '& .MuiButton-root': {
                fontStyle: 'italic',
                color: 'var(--gray-4)',
              },
            }}
          >
            <Button>
              <Typography variante="body1" onClick={forgotHandler}>
                Esqueci a senha
              </Typography>
            </Button>
            <Button>
              <Typography variante="body1" onClick={registerHandler}>
                Criar novo usuário
              </Typography>
            </Button>
          </Box>
          <IconButton sx={{ ml: 'auto' }} onClick={() => navigate('/central_de_ajuda')}>
            <HelpIcon sx={{ fontSize: 42, color: 'var(--gray-4)' }} />
          </IconButton>
        </Box>
      </Box>
      <Dialog open={openError} onClose={closeErrorHandler}>
        <DialogTitle sx={{ display: 'flex' }}>
          <IconButton sx={{ ml: 'auto' }} onClick={closeErrorHandler}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.8rem',
            '& a': { color: 'var(--gray-3)', textDecorationColor: 'var(--gray-3)' },
          }}
        >
          <Typography variant="body1" color="error">
            E-mail ou senha inválidos, confira-os e tente novamente!
          </Typography>
          <Typography variant="caption">ou</Typography>
          <Link href="#" onClick={forgotHandler}>
            Recuperar minha senha
          </Link>
          <Link href="#" onClick={centralHandler}>
            Central de Ajuda
          </Link>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Index() {
  return (
    <LoginLayout>
      <Login />
    </LoginLayout>
  );
}

export default Index;
